import React, { useState, useEffect } from "react";
import { Form, Button, Input, message, Modal, Spin, Steps } from "antd";
import { setMemberInfo } from "$DATA/userinfo";
import { realyNameRegThai, trimReg } from "$ACTIONS/reg";
const { Step } = Steps;
const { Item } = Form;

function RealNameVerify(props) {
    const {
        visible,
        closeModal,
        closeWallet,
        correctMemberInfo,
        isNext,
        nextStep,
        triggerFrom,
    } = props;
    const { getFieldError, getFieldDecorator, getFieldValue } = props.form;
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshMemberInfo, setIsRefreshMemberInfo] = useState(false);

    useEffect(() => {
        if (triggerFrom === "deposit" && visible) {
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl("kyc", "KYC");
        }
    }, [visible]);

    // M2 api PATCH FirstName欄位，需傳入value1/2，且不可為空
    const submit = () => {
        props.form.validateFields((err, values) => {
            if (!err) {
                setIsLoading(true);
                const [first, ...last] = values.realName.split(" ");
                setMemberInfo(
                    {
                        key: "FirstName",
                        value1: first,
                        value2: last.join(" "),
                    },
                    async (res) => {
                        if (triggerFrom === "deposit") {
                            Pushgtagdata(
                                `KYC`, 
                                'Submit Real Name', 
                                'KYC_S_RealName', 
                                res?.isSuccess ? 2 : 1,
                                [{
                                    customVariableKey: res?.isSuccess ? false : "KYC_S_RealName",
                                    customVariableValue: res?.isSuccess ? false : `รูปแบบชื่อจริงไม่ถูกต้อง`
                                }]
                            ); 
                        }
                        setIsLoading(false);
                        if (res && res.isSuccess) {
                            global.showResultModal("ยืนยันสำเร็จ"); //提交成功

                            setIsRefreshMemberInfo(true); // 设置 loading 状态
                            try {
                                const correctMemberInfoResult =  correctMemberInfo();
                                // 如果 correctMemberInfo 返回一个 Promise，等待它完成
                                if (correctMemberInfoResult && typeof correctMemberInfoResult.then === "function") {
                                    await correctMemberInfoResult;
                                }
                            } catch (correctMemberInfoError) {
                                console.error("Error in correctMemberInfo:", correctMemberInfoError);
                            } finally {
                                setIsRefreshMemberInfo(false); // 清除 loading 状态
                            }
                            closeModal();
                            if (isNext) {
                                nextStep();
                            }
                        } else {
                            message.error("Submission failed"); //提交失敗
                        }
                    }
                );
            }
        });
    };
    const isSubmittable =
        getFieldValue("realName") && !getFieldError("realName");
    return (
        <Modal
            className="general-modal verify-modal"
            width={400}
            visible={visible}
            title={"ยืนยันบัญชี"} //驗證賬戶
            centered={true}
            footer={null}
            maskClosable={true}
            onCancel={() => {
                closeModal();
                // 關閉存款驗證姓名彈窗，則關閉錢包
                closeWallet && closeWallet();
            }}
            closable={false}
        >
            <Spin
                spinning={isLoading || isRefreshMemberInfo}
                tip="กรุณารอสักครู่..." //提交中,请稍候
                style={{ backgroundColor: "initial" }}
            >
                {triggerFrom === "raf" && (
                    <div className="StepsBox">
                        <Steps current={0} size="small">
                            <Step />
                            <Step />
                        </Steps>
                    </div>
                )}
                <div className="realname-image">
                    <img src="/th/img/raf/realname.svg" />
                </div>
                <div className="verify-realname">
                    <p>
                        กรุณากรอกชื่อ นามสกุลจริงที่ตรงกับชื่อบัญชีธนาคารในการ
                        <br />
                        ฝาก-ถอน ป้องกันความล่าช้าหรือยอดเงินถูกยกเลิกเมื่อยืนยัน
                        <br />
                        แล้วจะไม่สามารถแก้ไขชื่อได้​
                    </p>
                    <div className="verify-realname-box">
                        <label>ชื่อ นามสกุล</label>
                        <p>กรุณาเว้นวรรคระหว่างชื่อและนามสกุล</p>
                        <div className="verify-realname-input">
                            <Item>
                                {getFieldDecorator("realName", {
                                    rules: [
                                        {
                                            required: true,
                                            message:
                                                "กรุณากรอกชื่อ-นามสกุลจริง",
                                        },
                                        {
                                            validator: (
                                                rule,
                                                value,
                                                callback
                                            ) => {
                                                if (
                                                    value &&
                                                    !realyNameRegThai.test(
                                                        value
                                                    )
                                                ) {
                                                    callback(
                                                        "รูปแบบชื่อจริงไม่ถูกต้อง"
                                                    );
                                                }
                                                callback();
                                            },
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="กรอกชื่อ นามสกุลโดยไม่ต้องกรอกคำนำหน้า"
                                        size="large"
                                        type="text"
                                        minLength={2}
                                        maxLength={50}
                                        autoComplete="off"
                                    />
                                )}
                            </Item>
                        </div>
                        <div className="verify-realname-btns">
                            <Button
                                type="primary"
                                size="large"
                                ghost
                                onClick={() => {
                                    closeModal();
                                    // 關閉存款驗證姓名彈窗，則關閉錢包
                                    closeWallet && closeWallet();
                                }}
                            >
                                ภายหลัง
                            </Button>
                            <Button
                                type="primary"
                                size="large"
                                disabled={!isSubmittable}
                                onClick={submit}
                            >
                                ยืนยัน
                            </Button>
                        </div>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
}

export default Form.create({ name: "RealNameVerify" })(RealNameVerify);
