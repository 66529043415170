import React from "react";
import Router from "next/router";
import { createForm } from "rc-form";
import { Button, Input, Row, Spin, Col, AutoComplete } from "antd";
import Item from "@/View/Formitem";
import {
    userName_reg,
    pwdReg,
    phone_reg,
    emailReg,
    emailReg2,
    pickNotNumberWithFirstNumberNon_zero,
    pickNotNumber,
} from "$ACTIONS/reg";
import { get, post } from "$ACTIONS/TlcRequest";
import { getAffiliateReferralCode, Cookie, getE2BBValue } from "$ACTIONS/helper";
import { ApiPort } from "$ACTIONS/TLCAPI";
import HostConfig from "$ACTIONS/Host.config";
import Captcha from "../Captcha";
import {
    MemberIcon,
    PasswordIcon,
    PhoneIcon,
    EmailIcon,
    RecommendIcon,
} from "./RegisterIcnoSvg";
import showMessage from "$ACTIONS/messageUtil";
class _Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: "",
            UserPwd: "",
            UserPhone: "",
            EmailAccount: "",
            loading: false,
            affcode: "",
            disabled: false,
            showAffCode: true,
            captchaVisible: false,
            challengeUuid: "",
            emailSuggestionList: [],
            phoneInputAble: true,
            dropdownVisible: false,
        };

        this.phonePrefix = [];
    }

    async componentDidMount() {
        const affCode = await getAffiliateReferralCode(); // Await the affiliate code
        const isDisabled =
            Cookie.GetCookieKeyValue("CO_affiliate") != "undefined" &&
            Cookie.GetCookieKeyValue("CO_affiliate") != "";
        this.setState({
            showAffCode: !sessionStorage.getItem("queleaReferrerId"),
            affcode: affCode,
            disabled: isDisabled,
        });

        get(ApiPort.PhonePrefix)
            .then((res) => {
                if (res.isSuccess && res.result) {
                    this.phonePrefix = res.result.prefixes || [];
                    this.setState({ phoneInputAble: true });
                } else {
                    this.setState({ phoneInputAble: false });
                }
            })
            .catch((error) => {
                this.setState({ phoneInputAble: false });
                console.log("PhonePrefix error:", error);
            });
    }

    startRegister = () => {
        global?.fbqTag && fbqTag('registerClick');
        const {
            NameState: UserName,
            passwordState: UserPwd,
            numberState: UserPhone,
            emailNameState: EmailAccount,
        } = this.props.form.getFieldsValue([
            "NameState",
            "passwordState",
            "numberState",
            "emailNameState",
        ]);
        if (UserName == "") {
            showMessage("error", "กรุณากรอกยูสเซอร์เนม", 100); //请输入用户名
            return;
        }
        if (userName_reg.test(UserName) == false) {
            showMessage(
                "error",
                "ยูสเซอร์เนมต้องประกอบด้วยตัวอักษร a-z และตัวเลข อย่างน้อย 6 ถึง 14 เท่านั้น",
                100
            );
            return;
        }
        if (UserPwd == "") {
            showMessage("error", "กรุณากรอกรหัสผ่าน", 100); //请输入密码
            return;
        }
        if (pwdReg.test(UserPwd) === false) {
            showMessage(
                "error",
                "รหัสผ่านต้องมีตัวอักษร a - z และตัวเลขอย่างน้อย 6-20 ตัว(ใส่อักขระพิเศษเช่น ^#$@ ได้)",
                100
            );
            //密码必须包含6-20个字符，必须同时包含字母和数字（可以包含^#$@中的特殊字符）
            return;
        }

        if (!this.phonePrefix?.length) {
            console.error("手机号前缀验证未加载完成");
            return;
        }
        const prefixCheck = this.phonePrefix.some(
            (v) => v.slice(0, UserPhone.length) === UserPhone.slice(0, v.length)
        );
        if (!prefixCheck || phone_reg.test(UserPhone.toString())) {
            showMessage("error", "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง", 100); //请输入正确的手机号
            return;
        }

        if (!emailReg.test(EmailAccount)) {
            showMessage("error", "กรุณากรอกอีเมลที่ถูกต้อง", 100); //请输入正确的邮箱
            return;
        }
        //this.getCaptchaSwitch();

        if (this.Captcha) {
            this.Captcha.getCaptchaInfo(UserName);
        }

        if (this.state.challengeUuid === "") {
            this.setState({ captchaVisible: true });
            return;
        }

        this.Register();
    };
    /***是否开启图形验证*/
    getCaptchaSwitch = () => {
        get(ApiPort.Captchaswitch).then((res) => {
            if (res) {
                if (res.isCaptchaOn) {
                    this.setState({ captchaVisible: true });
                }
            }
        });
    };
    /*注册*/
    Register() {
        const {
            NameState: UserName,
            passwordState: UserPwd,
            numberState: UserPhone,
            emailNameState: EmailAccount,
        } = this.props.form.getFieldsValue([
            "NameState",
            "passwordState",
            "numberState",
            "emailNameState",
        ]);
        this.setState({ loading: true });
        const UserData = {
            hostname: ApiPort.LOCAL_HOST,
            regWebsite: 37,
            language: "th-th",
            mobile: "66-" + UserPhone,
            email: EmailAccount,
            username: UserName,
            mediaCode: Cookie.GetCookieKeyValue("CO_Media") || null,
            referer:
                Cookie.GetCookieKeyValue("CO_Referer") ||
                sessionStorage.getItem("affCode") ||
                null,
            affiliateCode: this.state.affcode,
            password: UserPwd,
            brandCode: "FUN88",
            currency: "THB",
            queleaReferrerId: sessionStorage.getItem("queleaReferrerId"),
            challengeUuid: this.state.challengeUuid,
            blackBoxValue: getE2BBValue(),
            deviceSignatureBlackBox: getE2BBValue(),
        };
        console.log("UserData", UserData);
        post(ApiPort.PostRegister, UserData)
            .then((res) => {
                if (res) {
                    if (res.isSuccess == true) {
                        this.props.showmodal("1");
                        sessionStorage.removeItem("queleaReferrerId");
                        this.props.login(UserName, UserPwd, "Register");
                        global?.fbqTag && fbqTag('register');
                    } else if (res.isSuccess == false) {
                        if (res.errors?.[0]?.errorCode === "MEM00041") {
                            this.props.form.setFields({
                                emailNameState: {
                                    value: this.props.form.getFieldValue(
                                        "emailNameState"
                                    ),
                                    errors: [
                                        "อีเมลนี้ไม่สามารถใช้งานได้ กรุณากรอกอีเมลอื่น",
                                    ], //此Email無法使用，請使用其他Email
                                },
                            });
                        } else if (res.errors?.[0]?.errorCode === "MEM00026") {
                            this.props.form.setFields({
                                NameState: {
                                    value: this.props.form.getFieldValue(
                                        "NameState"
                                    ),
                                    errors: [
                                        "ยูสเซอร์เนมนี้ไม่สามารถใช้งานได้ กรุณากรอกยูสเซอร์เนมอื่น",
                                    ], //此account無法使用，請使用其他account
                                },
                            });
                        } else if (res?.errors?.[0]?.description || res?.message) {
                            const errorMessage = res?.errors?.[0]?.description || res?.message;
                            showMessage("error", errorMessage, 100);
                        }
                    }
                    Pushgtagdata(
						"Register", 
						"Submit Register", 
						"Register_C_Register",
						res.isSuccess ? 2 : 1,
						[{
							customVariableKey: res.isSuccess ? false : "Register_S_Register_ErrorMsg",
							customVariableValue: res.isSuccess ? false : res.errors?.[0]?.description,
						},
						{
							customVariableKey: res.isSuccess ? false : "Register_S_Register_AffiliateCode",
							customVariableValue: res.isSuccess ? false : this.state.affcode,
						}]
					);
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log(error);
            })
            .finally(() => {
                this.setState({ challengeUuid: "" });
            });
    }

    RegisterFormdata = (e, t) => {
        if (t == "affcode") {
            this.setState({
                affcode: e.target.value.replace(pickNotNumber, ""),
            });
        }
    };
    onMatch = (id) => {
        this.setState(
            {
                challengeUuid: id,
                captchaVisible: false,
            },
            () => {
                this.Register();
            }
        );
    };

    /**
     * @description: 如果通过验证 提交数据
     * @param {*}
     * @return {*}
     */

    submitBtnEnable = () => {
        let error = Object.values(this.props.form.getFieldsError()).some(
            (v) => v !== undefined
        );
        let errors = Object.values(this.props.form.getFieldsValue()).some(
            (v) => v == "" || v == undefined
        );
        return !errors && !error;
    };
    handleSearch = (value) => {
        let result;
        const emailExtension = [
            "hotmail.com",
            "gmail.com",
            "icloud.com",
            "outlook.com",
            "hotmail.co.th",
            "outlook.co.th",
            "live.com",
            "yahoo.com",
            "msn.com",
            "windowslive.com",
        ];
        if (!value || value.indexOf("@") === -1) {
            result = [];
        } else {
            const emailExtensionStart = value.indexOf("@");
            const emailList = emailExtension.map(
                (item) => `${value.slice(0, emailExtensionStart + 1)}${item}`
            );
            const emailExtensionList = emailList.filter(
                (item) => item.includes(value) && item !== value
            );
            // console.log("emailExtensionList", emailExtensionList);
            result = emailExtensionList;
        }
        this.setState({ emailSuggestionList: result });
    };
    handleDropdownVisibleChange = (open) => {
        this.setState({ dropdownVisible: open });
    };
    render() {
        const { affcode, captchaVisible } = this.state;
        const { closeModal } = this.props;
        const { getFieldDecorator, getFieldError } = this.props.form;
        return (
            <Spin
                spinning={this.state.loading}
                tip="กำลังลงทะเบียน..." //正在注册中...
                style={{ backgroundColor: "initial" }}
            >
                <div className="user-modal">
                    {/* ------------------ 用戶名 ------------------*/}
                    <Item errorMessage={getFieldError("NameState")}>
                        <div className="IputBox">
                            {getFieldDecorator("NameState", {
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณากรอกยูสเซอร์เนม",
                                    }, //请输入用户名
                                    {
                                        validator: (rule, value, callback) => {
                                            if (
                                                value &&
                                                !userName_reg.test(value)
                                            ) {
                                                callback(
                                                    "ยูสเซอร์เนมต้องประกอบด้วยตัวอักษร a-z และตัวเลข\n อย่างน้อย 6 ถึง 14 เท่านั้น"
                                                ); //用户名长度必须至少有6个字符，不能超过14个字符，仅可使用字母 'A-Z', 'a-z' , 数字 '0-9'。
                                            }
                                            callback();
                                        },
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    placeholder="ยูสเซอร์เนม" //用户名
                                    prefix={<MemberIcon />}
                                    maxLength={14}
                                />
                            )}
                        </div>
                    </Item>

                    {/* ------------------ 密碼 ------------------*/}
                    <Item errorMessage={getFieldError("passwordState")}>
                        <div className="IputBox">
                            {getFieldDecorator("passwordState", {
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณากรอกรหัสผ่าน",
                                    }, //请输入密码
                                    {
                                        validator: (rule, value, callback) => {
                                            if (value && !pwdReg.test(value)) {
                                                callback(
                                                    "รหัสผ่านต้องมีตัวอักษร a - z และตัวเลขอย่างน้อย 6-20 ตัว (ใส่อักขระพิเศษเช่น ^#$@ ได้)"
                                                ); //密码必须包含6-20个字符，字符只限于使用字母和数字。（可以包含 ^＃$@ 中的特殊字符）。
                                            }
                                            callback();
                                        },
                                    },
                                ],
                            })(
                                <Input.Password
                                    size="large"
                                    prefix={<PasswordIcon />}
                                    placeholder="รหัสผ่าน" //密码
                                    maxLength={20}
                                />
                            )}
                        </div>
                    </Item>

                    {/* ------------------ 确认密碼 ------------------*/}
                    {/* <div className="IputBox">
						<Input.Password
							size="large"
							placeholder="请确认密码"
							onChange={(e) => this.RegisterFormdata(e, 'confirmpwd')}
							maxLength={16}
						/>
					</div> */}

                    {/* ------------------ 联系电话 ------------------*/}
                    <Item errorMessage={getFieldError("numberState")}>
                        <div className="IputBox">
                            {getFieldDecorator("numberState", {
                                getValueFromEvent: (e) => {
                                    const formatValue = e.target.value.replace(
                                        pickNotNumberWithFirstNumberNon_zero,
                                        ""
                                    );
                                    return formatValue;
                                },
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณากรอกเบอร์โทร", //请输入联系电话
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            //最低位数
                                            let LengthCheck = value.length < 9; //minLength;
                                            //Prefix檢測
                                            let prefixCheck =
                                                this.phonePrefix?.length &&
                                                this.phonePrefix.some(
                                                    (v) =>
                                                        v.slice(
                                                            0,
                                                            value.length
                                                        ) ===
                                                        value.slice(0, v.length)
                                                );
                                            if (value && LengthCheck) {
                                                callback(
                                                    "หมายเลขโทรศัพท์ต้องมี 9 หลัก ไม่ต้องใส่ 0 นำหน้า" //有效手机号码必须为9位数。
                                                );
                                            }
                                            if (value && !prefixCheck) {
                                                callback(
                                                    "หมายเลขโทรศัพท์ไม่ถูกต้อง" //電話號碼不正確
                                                );
                                            }
                                            callback();
                                        },
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    // addonBefore="+66"
                                    className="registerPhone"
                                    prefix={<PhoneIcon text={"+ 66"} />}
                                    placeholder="เบอร์โทรตัดเลข 0" //联系电话
                                    maxLength={9}
                                    disabled={!this.state.phoneInputAble}
                                />
                            )}
                        </div>
                    </Item>

                    {/* ------------------ 电子邮箱 ------------------*/}
                    <Item
                        errorMessage={
                            this.state.dropdownVisible &&
                            this.state.emailSuggestionList?.length > 0
                                ? ""
                                : getFieldError("emailNameState")
                        }
                    >
                        <div className="IputBox">
                            {getFieldDecorator("emailNameState", {
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณากรอกอีเมล", //请输入电子邮箱
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            const mailPrefixLessThanThree =
                                                value.split("@")[0].length < 3;
                                            // if (value && value.length > 50) {
                                            //     callback(
                                            //         "กรอกได้สูงสุด 50 ตัวอักษร"
                                            //     );
                                            // }
                                            if (
                                                value &&
                                                (!emailReg.test(value) ||
                                                    !emailReg2.test(value) ||
                                                    value.length > 100 ||
                                                    mailPrefixLessThanThree)
                                            ) {
                                                callback(
                                                    "รูปแบบอีเมลไม่ถูกต้อง"
                                                ); //错误电邮格式
                                            }
                                            callback();
                                        },
                                    },
                                ],
                            })(
                                <AutoComplete
                                    style={{ width: "100%" }}
                                    onChange={this.handleSearch}
                                    dataSource={this.state.emailSuggestionList.map(
                                        (email) => (
                                            <AutoComplete.Option
                                                key={email}
                                                style={{ textAlign: "left" }}
                                            >
                                                {email}
                                            </AutoComplete.Option>
                                        )
                                    )}
                                    onDropdownVisibleChange={
                                        this.handleDropdownVisibleChange
                                    }
                                >
                                    <Input
                                        size="large"
                                        style={{ height: "45px" }}
                                        prefix={<EmailIcon />}
                                        placeholder="อีเมล" //电子邮箱
                                        maxLength={50}
                                    />
                                </AutoComplete>
                            )}
                        </div>
                    </Item>
                    {/* ------------------ 推荐代码 ------------------*/}
                    {this.state.showAffCode ? (
                        <div className="IputBox fix-not-required-text">
                            <Input
                                size="large"
                                prefix={<RecommendIcon />}
                                placeholder="รหัสพันธมิตร" //推荐代码
                                disabled={this.state.disabled ? true : false}
                                defaultValue={affcode}
                                value={affcode}
                                key={this.state.disabled ? affcode : ""}
                                onChange={(e) =>
                                    this.RegisterFormdata(e, "affcode")
                                }
                                maxLength={6}
                            />
                            <div className="not-required-text">
                                (ไม่จำเป็นต้องกรอก){/* 非必填 */}
                            </div>
                        </div>
                    ) : null}

                    {/* ------------------ 注册 ------------------*/}
                    <Button
                        size="large"
                        type="primary"
                        block
                        onClick={() => {
                            this.startRegister();
                        }}
                        disabled={!this.submitBtnEnable()}
                    >
                        ลงทะเบียน {/* 注册 */}
                    </Button>
                </div>

                <div className="protocol-box">
                    <p className="protocol">
                        <div>
                            คลิกลงทะเบียนเพื่อยืนยันว่าคุณอายุ 21 ปีขึ้นไปและ
                        </div>
                        <div>
                            <a
                                onClick={() => {
                                    closeModal(() => {
                                        Router.push("/faq/gamble-responsibly");
                                    });
                                    Pushgtagdata("Register", "View TC", "Register_V_T&C");
                                }}
                            >
                                ยอมรับเงื่อนไขข้อตกลง {/* 条款 */}
                            </a>
                            {" รวมถึง "}
                            <a
                                onClick={() => {
                                    closeModal(() => {
                                        Router.push("/faq/privacy-policy");
                                    });
                                    Pushgtagdata("Register", "View TC", "Register_V_T&C");
                                }}
                            >
                                นโยบายความเป็นส่วนตัว {/* 隐私政策 */}
                            </a>
                            จากเรา
                        </div>
                    </p>
                </div>

                <Captcha
                    captchaVisible={captchaVisible}
                    setCaptchaVisible={(v) => {
                        this.setState({ captchaVisible: v });
                    }}
                    onMatch={this.onMatch}
                    getCaptchaInfo={(props) => {
                        this.Captcha = props;
                    }}
                    type="register"
                />
            </Spin>
        );
    }
}

export default createForm({ fieldNameProp: "register" })(_Register);
