import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Popover, Button, Icon, Select, Modal } from "antd";
import ImageWithFallbackLocal from "@/ImageWithFallback/imgLocal";
import ImageWithFallback from "@/ImageWithFallback";
import { getCMSFooter } from "$STORE/thunk/commonDataThunk";
import LazyLoad from "react-lazyload";
import { getPreferredImage } from "$ACTIONS/helper";

const ImageHoverItem = ({ item, supportFormats, type }) => {
    // 取得 hover 和 default 圖片
    const deskImg = getPreferredImage(
        item,
        ["desktop_image_avif", "desktop_image_webp", "desktop_image"],
        supportFormats
    );
    const deskImgHover = getPreferredImage(
        item,
        [
            "desktop_image_hover_avif",
            "desktop_image_hover_webp",
            "desktop_image_hover",
        ],
        supportFormats
    );

    const [currentImg, setCurrentImg] = useState(deskImg);

    // 定義 hover 時切換圖片的事件
    const handleMouseEnter = () => setCurrentImg(deskImgHover);
    const handleMouseLeave = () => setCurrentImg(deskImg);

    return type === "platform" ? (
        <Col
            className="platform-item"
            span={3}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => {
                const url = item?.desktop_forwarding_url;
                if (url) {
                    window.open(url, "_blank");
                }
            }}
        >
            <ImageWithFallback
                src={currentImg}
                width={140}
                height={36}
                alt={item.desktop_text || "footer-icon"}
                fallbackSrc="/th/img/logo.svg"
            />
        </Col>
    ) : (
        <Popover
            content={item.desktop_text}
            title=""
            trigger="hover"
            overlayClassName="footer-popover"
        >
            <li
                className="icon-item2"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                    if (item.desktop_forwarding_url) {
                        window.open(item.desktop_forwarding_url, "_blank");
                    }
                    if (type === "social_media") {
                        Pushgtagdata("Home", "Contact CS", "Home_Footer_C_CS");
                        Modal.info({
                            title: ``,
                            className: "socialmedia-line-modal",
                            centered: true,
                            maskClosable: true,
                            width: "fit-content",
                            content: (
                                <div>
                                    <img
                                        src={getPreferredImage(
                                            item,
                                            [
                                                "desktop_secondary_image_avif",
                                                "desktop_secondary_image_webp",
                                                "desktop_secondary_image",
                                            ],
                                            supportFormats
                                        )}
                                        alt={item.desktop_text}
                                    />
                                </div>
                            ),
                        });
                    }
                }}
            >
                <img src={currentImg} alt={item.desktop_text} />
            </li>
        </Popover>
    );
};

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            readMore: false,
        };
    }
    componentDidMount() {
        this.props.getCMSFooter();
    }
    render() {
        const { isHomePage, supportFormats, footerData } = this.props;
        return (
            <React.Fragment>
                {isHomePage && (
                    <LazyLoad height={454} offset={100}>
                        <div className="common-distance-wrap cooperate-wrap">
                            <div className="common-distance clear-padding">
                                <h1 className="cooperate-title">หุ้นส่วน</h1>
                                {/* 合作伙伴 */}
                                <Row
                                    type="flex"
                                    justify="center"
                                    align="bottom"
                                >
                                    {Array.isArray(footerData?.sponsorship)
                                        ? footerData?.sponsorship?.map(
                                              (item, index) => {
                                                // 根据 index 动态调整图片比例
                                                const width = index === 0 ? 480 : index === 1 ? 628 : 2;
                                                const height = index === 0 ? 299 : index === 1 ? 319 : 1;

                                                  return (
                                                      <Col
                                                          key={`sponsorship${index}`}
                                                          span={index === 0 ? 10 : 12}
                                                          offset={index === 0 ? 2 : 0}
                                                          onClick={() => {
                                                              const url = item?.desktop_forwarding_url;
                                                              if (url) {
                                                                  window.open(url, "_blank");
                                                              }
                                                          }}
                                                      >
                                                          <LazyLoad
                                                              height={808}
                                                              offset={100}
                                                              once
                                                          >
                                                              <ImageWithFallback
                                                                  src={getPreferredImage(
                                                                      item,
                                                                      [
                                                                          "desktop_image_avif",
                                                                          "desktop_image_webp",
                                                                          "desktop_image",
                                                                      ],
                                                                      supportFormats
                                                                  )}
                                                                  width={width}
                                                                  height={height}
                                                                  alt="app-picture"
                                                                  fallbackSrc="/th/img/logo.svg"
                                                              />
                                                          </LazyLoad>
                                                      </Col>
                                                  );
                                              }
                                          )
                                        : null}
                                </Row>
                            </div>
                        </div>
                    </LazyLoad>
                )}
                <div className="common-distance-wrap footer-platform-wrap">
                    <div className="common-distance">
                        <Row className="footer-platform">
                            <Col span={!this.props.isLogin ? 8 : 10}>
                                <h4>ทางเลือกการจ่ายเงิน</h4>
                                {/* 支付方式 */}
                                <ul className="icon-list payment_options">
                                    {footerData?.payment_options?.map(
                                        (item, i) => {
                                            return (
                                                <ImageHoverItem
                                                    key={`payment${i}`}
                                                    item={item}
                                                    supportFormats={
                                                        supportFormats
                                                    }
                                                />
                                            );
                                        }
                                    )}
                                </ul>
                            </Col>
                            <Col span={!this.props.isLogin ? 4 : 5}>
                                <h5>โซเชียลมีเดีย</h5>
                                {/* 关注我们 */}
                                <ul className="icon-list social_media">
                                    {footerData?.social_media?.map(
                                        (item, i) => {
                                            return (
                                                <ImageHoverItem
                                                    key={`social_media${i}`}
                                                    item={item}
                                                    supportFormats={
                                                        supportFormats
                                                    }
                                                    type={"social_media"}
                                                />
                                            );
                                        }
                                    )}
                                </ul>
                            </Col>
                            <Col span={!this.props.isLogin ? 4 : 5}>
                                <h5>ความรับผิดชอบ</h5>
                                {/* 博彩责任 */}
                                <ul className="icon-list regulation">
                                    {footerData?.regulation?.map((item, i) => {
                                        return (
                                            <ImageHoverItem
                                                key={`regulation${i}`}
                                                item={item}
                                                supportFormats={supportFormats}
                                            />
                                        );
                                    })}
                                </ul>
                            </Col>
                            <Col span={4}>
                                <h5>ความปลอดภัย</h5>
                                {/* 信息安全 */}
                                <ul className="icon-list info_security">
                                    {footerData?.info_security?.map(
                                        (item, i) => {
                                            return (
                                                <ImageHoverItem
                                                    key={`info_security${i}`}
                                                    item={item}
                                                    supportFormats={
                                                        supportFormats
                                                    }
                                                />
                                            );
                                        }
                                    )}
                                </ul>
                            </Col>
                            {!this.props.isLogin && (
                                <Col span={4}>
                                    {/* <h5>语言</h5> */}
                                    <Select
                                        size="small"
                                        dropdownClassName="footer-small-drop"
                                        suffixIcon={
                                            <img src="/th/img/selfexclusion/icon.svg" />
                                        }
                                        defaultValue="th"
                                        onChange={(val) => {
                                            Pushgtagdata(
                                                "Home",
                                                "Switch Language",
                                                `Home_Footer_C_${val.toUpperCase()}`
                                            );
                                            window.location.href = `/${val}`;
                                        }}
                                    >
                                        <Select.Option
                                            value="cn"
                                            className="tlc-sprite item"
                                        >
                                            <img src="/th/img/home/footer/china.png" />
                                            <span>中文</span>
                                        </Select.Option>
                                        <Select.Option
                                            value="th"
                                            className="tlc-sprite item"
                                        >
                                            <img src="/th/img/home/footer/thailand.png" />
                                            <span>ภาษาไทย</span>
                                        </Select.Option>
                                        <Select.Option
                                            value="vn"
                                            className="tlc-sprite item"
                                        >
                                            <img src="/th/img/home/footer/vietnam.png" />
                                            <span>Tiếng Việt</span>
                                        </Select.Option>
                                    </Select>
                                    {/* {suffixIcon={<CaretDownFilled />}} */}
                                </Col>
                            )}
                        </Row>
                        <div>
                            <h4>ผู้ให้บริการ</h4>
                            {/* 游戏平台 */}
                            <Row gutter={16}>
                                {footerData?.gaming_platform?.map((item, i) => {
                                    return (
                                        <ImageHoverItem
                                            key={`gaming_platform${i}`}
                                            item={item}
                                            supportFormats={supportFormats}
                                            type={"platform"}
                                        />
                                    );
                                })}
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="common-distance-wrap footer-copyright-wrap">
                    <div className="common-distance clear-padding">
                        <Row>
                            <Col span={3} className="copyright-picture">
                                <ImageWithFallbackLocal
                                    src={`/th/img/home/part/img.png`}
                                    width={122}
                                    height={118}
                                    alt={"card"}
                                    fallbackSrc="/th/img/logo.svg"
                                />
                            </Col>
                            <Col span={21} className="copyright-article">
                                <p>
                                    www.fun88.com is operated by E Gambling
                                    Montenegro d.o.o. (81000 Podgorica,
                                    Moskovska br. 65., reg. no.5-0615951) in
                                    cooperation with OG GLOBAL ACCESS LIMITED
                                    (P.O. Box 3340, Road Town, Tortola, British
                                    Virgin Islands.
                                </p>
                                <p>
                                    E Gambling Montenegro d.o.o. (81000
                                    Podgorica, Moskovska br. 65., reg.
                                    no.5-0615951) hereby certifies that under
                                    the concession (serial no. AA 001588,
                                    numeric no. 133-01/15 4A and 1ER) and
                                    approval (no. 02/01-118/4), organize and
                                    operate games of chance in Montenegro on
                                    website www.fun88.com , in accordance with
                                    the Agreement, concluded between E Gambling
                                    Montenegro d.o.o. and OG GLOBAL ACCESS
                                    LIMITED (P.O. Box 3340, Road Town, Tortola,
                                    British Virgin Islands, reg. no. 1923130) on
                                    07 October 2021
                                </p>
                            </Col>
                        </Row>
                        <div className="copyright-content">
                            <p>FUN88 ดำเนินการโดย OG GLOBAL ACCEDD LIMITED </p>
                            <p>
                                มีการควบคุมและออกใบอนุญาต ตั้งแต่วันที่ 7 ตุลาคม
                                2021 โดย E-Gambling Montenegro
                            </p>
                            <p>สงวนลิขสิทธิ์โดย FUN88© </p>
                        </div>
                    </div>
                    {!this.props.isLogin && (
                        <React.Fragment>
                            <div
                                className={`common-distance clear-padding footer-description-wrap${
                                    this.state.readMore ? " active" : ""
                                }`}
                            >
                                <h2>
                                    Fun88 เว็บพนันกีฬาและคาสิโนที่ดีที่สุดในไทย
                                    ความบันเทิงและเงินรางวัลรอคุณอยู่
                                </h2>
                                <div className="footer-description">
                                    <p>
                                        Fun88 Thailand
                                        เว็บพนันบอลและกีฬาออนไลน์ที่ครบครัน
                                        รายใหญ่และดีที่สุดในประเทศไทย
                                        เปิดให้บริการอย่างเต็มรูปแบบแล้ววันนี้
                                        ด้วยระบบการสมัครสมาชิกออโต้ ฝากถอนเงิน
                                        สะดวก ผ่านธนาคารไทย (แอปมือถือ)
                                        ปลอดภัยด้วยระบบรักษาความเป็นส่วนตัวของข้อมูลสมาชิก
                                        พร้อมขั้นตอนการยืนยันตัวตนผู้เล่น
                                        นอกจากนี้ Fun88 ยังเป็นเว็บรับพนัน
                                        ถูกกฎหมายเรามีใบอนุญาตอย่างเป็นทางการจากรัฐบาลฟิลิปปินส์
                                        ผ่านเกณฑ์และอยู่ภายใต้การตรวจสอบและควบคุมโดยองค์กรที่เกี่ยวข้อง
                                        ผู้เล่นจึงมั่นใจได้ว่าสามารถบันเทิงกับ
                                        การเดิมพันออนไลน์ได้อย่างสนุกและยุติธรรม
                                    </p>
                                    <p>เดิมพันกีฬากับ Fun88 เว็บบอลดีที่สุด</p>
                                    <p>
                                        <span>Fun88 พนันกีฬา</span>
                                        เว็บไซต์ที่ถูกออกแบบมาเพื่อเดิมพันออนไลน์
                                        คือแหล่งรวมตลาดการเดิมพันกีฬาที่ใหญ่ที่สุดในไทยและเอเชีย
                                        เนื่องจากคุณจะได้พบกับแพลตฟอร์มสำหรับการแทงบอลและกีฬาทุกชนิด
                                        รวมถึง 4 แพลตฟอร์ม
                                        และเพื่อให้คุณได้เลือกเดิมพันได้ง่ายกว่าเดิม
                                        มาดูพร้อมๆ
                                        กันว่าแต่ละแพลตฟอร์มจะมีจุดเด่นใดบ้าง
                                    </p>
                                </div>
                                <ul className="vender-description">
                                    <li>
                                        <h3>SABA กีฬา</h3>
                                        <p>
                                            ราคาบอลสด: 100+ ต่อวัน
                                            <br />
                                            ฟีเจอร์: ดูบอลสด, แผนผังคะแนน,
                                            บิลบอลชุด (พาร์เลย์)
                                            <br />
                                            รูปแบบราคา: จีน, อินโด, อเมริกัน,
                                            ทศนิยม, มาเลย์
                                            <br />
                                            เกมพนันอื่นๆ: เกมหมายเลข,
                                            พนันกีฬาจำลอง, พนันอีสปอร์ต, คีโน
                                        </p>
                                        <p>
                                            ห้องแทงบอลออนไลน์ที่ฮอตที่สุดของ
                                            Fun88 Thai ที่นี่
                                            ผู้เล่นสามารถวางเดิมพันฟุตบอลและกีฬาทุกชนิดได้
                                            ทั้งตลาดล่วงหน้าและแทงบอลสด
                                            โดยจะมีตัวกรองให้เลือก 3 แบบหลักๆ
                                            ได้แก่ ล่วงหน้า, วันนี้ และ
                                            สดซึ่งสำหรับผู้เล่นที่ต้องการร่วมสนุกกับ
                                            Fun88 พนันกีฬาสด
                                            ทางเว็บไซต์จะเปิดให้เลือกอัตราต่อรองหลากหลาย
                                            มากกว่า 100 อัตราต่อวัน
                                        </p>
                                        <p>
                                            โดยมีราคาต่อรองแบบเอเชียนแฮนดิแคปแสดงเป็นราคาหลัก
                                            เหมาะสำหรับผู้เล่นไทย
                                            นอกจากนี้ที่นี่ยังมีเกมการเดิมพันอื่นๆ
                                            ให้ผู้เล่นได้เปลี่ยนบรรยากาศโดยไม่ต้องย้ายห้อง
                                            คุณสามารถ เล่นหวยออนไลน์, เกมหมายเลข
                                            พนันสูงต่ำ, คี่คู่, แดงน้ำเงิน ฯลฯ
                                            หรือเลือกพนันอีสปอร์ต และกีฬาจำลอง
                                            ระหว่างรอราคาแมตช์บอลสด
                                        </p>
                                    </li>
                                    <li>
                                        <h3>IM กีฬา</h3>
                                        <p>
                                            ราคาบอลสด: 60+ ต่อวัน
                                            <br />
                                            ฟีเจอร์: ผลบอลสด,
                                            ข้อมูลสถิติ,รูปแบบราคา: มาเลย์,
                                            ฮ่องกง, ยูโร, อินโด
                                            <br />
                                            เกมพนันอื่นๆ: ไม่รองรับ
                                        </p>
                                        <p>
                                            อีกหนึ่งทางเลือกน่าสนุกสำหรับการพนันกีฬาออนไลน์ของผู้เล่นไทย
                                            ได้แก่ แพลตฟอร์มแทงบอลออนไลน์ Fun88
                                            ห้อง IM กีฬา
                                            ระบบที่สร้างและพัฒนาโดยค่าย
                                            InplayMatrix
                                            ซึ่งจุดเด่นของการเดิมพันกับห้องนี้ก็คือ
                                            ผู้พนันสามารถเช็คผลการแข่งขันแบบสดๆ
                                            ได้ทันที ง่ายกว่าแพลตฟอร์มอื่นๆ
                                            จุดแตกต่างอีกแห่งสำหรับ IM กีฬาก็คือ
                                            แถบเมนูหลักสำหรับเลือก
                                            ทัวร์นาเมนต์การแข่งขันรายการใหญ่ๆ
                                            เช่น พนันบาส NBA, บอลพรีเมียร์ลีก,
                                            อิตาลี เซเรียอา, สเปน ลาลีกา
                                            และอื่นๆ อีกมากมาย
                                            นอกจากนี้ผู้เล่นอาจเลือกเดิมพันจากแถบเมนูเพิ่มเติม
                                            ทางด้านขวามือ (บนหน้าจอเดสก์ท็อป)
                                            ระบบจะแสดงอัตราต่อรองของลีก/แมตช์ไฮไลท์
                                            ที่คุณสามารถคลิกพนันได้รวดเร็วกว่าโดยไม่ต้องเสียเวลาค้นหาแมตช์นั้นๆ
                                            ในตารางราคาหลัก
                                        </p>
                                    </li>
                                    <li>
                                        <h3>CMD กีฬา</h3>
                                        <p>
                                            ราคาบอลสด: 30+ ต่อวัน
                                            (ราคาต่อรองทั้งหมด 1000+
                                            อัตราต่อวัน)
                                            <br />
                                            ฟีเจอร์: สถิติสด,
                                            การคาดการณ์ล่วงหน้า, ถ่ายทอดสด
                                            <br />
                                            รูปแบบราคา: มาเลย์, ฮ่องกง, ยูโร,
                                            อินโด
                                            <br />
                                            เกมพนันอื่นๆ: อีสปอร์ต
                                        </p>
                                        <p>
                                            แพลตฟอร์ม CMD
                                            จะเหมาะกับนักพนันที่ชื่นชอบการเลือกอัตราต่อรองที่มีหลายๆ
                                            ตัวเลือก หลายๆ ราคา
                                            เนื่องจากนำเสนอมากถึง 1000+
                                            อัตราต่อวัน และยังมีฟีเจอร์พิเศษ
                                            สำหรับรับชม การถ่ายทอดสด
                                        </p>
                                        <p>
                                            อย่างไรก็ตาม
                                            การจะเริ่มใช้ทุกฟีเจอร์ของห้อง CMD
                                            กีฬานั้น
                                            สมาชิกจะต้องเข้าสู่ระบบและมีเงินฝากในบัญชีของ
                                            Fun88 ก่อน
                                        </p>
                                    </li>
                                    <li>
                                        <h3>BTi กีฬา</h3>
                                        <p>
                                            ราคาบอลสด: 60+ ต่อวัน
                                            <br />
                                            ฟีเจอร์: เข้าชมสตรีมมิ่ง
                                            <br />
                                            รูปแบบราคา: เดซิมอล, มาเลเซีย,
                                            ฮ่องกง, อินโดนีเซีย
                                            <br />
                                            เกมพนันอื่นๆ: ไม่รองรับ
                                        </p>
                                        <p>
                                            ห้องพนันกีฬาทั้งแมตช์สดและล่วงหน้า
                                            BTi
                                            อีกหนึ่งทางเลือกสำหรับคอสปอร์ตที่ต้องการเช็คและรับราคาบอลดีๆ
                                            จาก Fun88
                                            โดยที่ห้องนี้เมื่อเปรียบกับแพลตฟอร์มอื่นๆ
                                            แล้วอาจจะมี ฟีเจอร์พิเศษน้อยกว่า
                                            แต่เรื่องของโอกาสในการทำกำไรนั้นไม่น้อยไปกว่ากัน
                                            รวมทั้งที่นี่ยังมีการเข้าร่วม
                                            <span>โปรโมชั่น Fun88</span>{" "}
                                            จัดแคมเปญให้สมาชิกสามารถรับโบนัสฟรีได้อย่างต่อเนื่อง
                                        </p>
                                    </li>
                                </ul>
                                <div className="footer-content">
                                    <h3>Fun88 คาสิโนสด เกมฮิตบน 8 ล็อบบี้</h3>
                                    <p>
                                        บาคาร่าสด, รูเล็ต, แบล็คแจ็ค
                                        ไม่ว่าคุณจะมองหาเกมไหน{" "}
                                        <span>Fun88 คาสิโนสด</span>{" "}
                                        มีเปิดให้บริการและลุ้นเงินรางวัลอย่างครบครัน
                                        สมาชิกสามารถใช้ตัวกรองในการค้นหาเกม
                                        หรือล็อบบี้ไลฟ์ดีลเลอร์ที่ต้องการได้อย่างสะดวกรวดเร็ว
                                        ดังนี้
                                    </p>
                                    <div className="list">
                                        <div>เลือกจากซอฟท์แวร์ที่คุณชอบ</div>
                                        <ul>
                                            <li>S พาเลซ (ใหม่)</li>
                                            <li>FUN88 พาเลซ</li>
                                            <li>PP พาเลซ (ฮอต)</li>
                                            <li>สกาย พาเลซ</li>
                                            <li>WM พาเลซ</li>
                                            <li>EVO พาเลซ</li>
                                            <li>อี พาเลซ</li>
                                            <li>แฮปปี้ พาเลซ</li>
                                        </ul>
                                    </div>
                                    <div className="list">
                                        <div>เลือกจากประเภทเกมคาสิโน</div>
                                        <ul>
                                            <li>บาคาร่า</li>
                                            <li>ซิกโบ</li>
                                            <li>รูเล็ต</li>
                                            <li>มังกร เสือ</li>
                                            <li>อื่นๆ</li>
                                        </ul>
                                    </div>
                                    <p>
                                        ทั้งแพลตฟอร์มและเกมยอดนิยมในหมวดคาสิโนสดบน
                                        Fun88 Thailand นี้
                                        จะมีการปรับปรุงและอัปเดตอย่างต่อเนื่อง
                                        เพื่อให้ผู้เล่นได้รีเฟรชและมีโอกาสสนุกกับเกมใหม่ๆ
                                        อยู่ตลอดเวลา อย่างไรก็ตาม
                                        ที่นี่จะยังคงนำเสนอเกมคาสิโนสดยอดนิยม
                                        อย่างเช่น บาคาร่าสดออนไลน์, รูเล็ตสด,
                                        แบล็คแจ็คสด, เสือมังกร, ไฮโลสดออนไลน์
                                        ซึ่งอาจจะมีการอัปเดตเวอร์ชั่นย่อยๆ
                                        เพิ่มเติม เพื่อให้ผู้เล่นได้มีทางเลือก
                                    </p>
                                    <p>
                                        8 อันดับบาคาร่าสด Fun88 ที่ต้องลอง
                                        <br />
                                        สำหรับแฟนๆ เกมไพ่บาคาร่าออนไลน์
                                        เรามีรายชื่อ 7 เกมบาคาร่าสดที่ห้ามพลาด
                                        หากคุณมีโอกาสได้สมัครร่วมสนุกกับ Fun88
                                        ไปดูพร้อมๆ กันเลยว่ามีเกมไหนบ้าง
                                        <br />
                                        อันดับ 1 บาคาร่าสายฟ้า EVO พาเลซ
                                        <br />
                                        อันดับ 2 โกลเด้นเวลท์บาคาร่า EVO พาเลซ
                                        <br />
                                        อันดับ 3 บาคาร่า S พาเลซ
                                        <br />
                                        อันดับ 4 บาคาร่าประกันภัย S พาเลซ
                                        <br />
                                        อันดับ 5 บาคาร่า PP พาเลซ
                                        <br />
                                        อันดับ 6 สปีดบาคาร่า PP พาเลซ
                                        <br />
                                        อันดับ 7 บาคาร่า EVO พาเลซ
                                        <br />
                                        อันดับ 8 สควีซบาคาร่า EVO พาเลซ
                                    </p>
                                    <p>
                                        เกมด้านบนนี้ได้รับการจัดอันดับผ่านความนิยมของสมาชิกที่เข้าล็อกอินและร่วมสนุกกับ
                                        Fun88 อย่างต่อเนื่อง
                                        โดยแต่ละห้องนั้นเป็นการใช้แพลตฟอร์มและซอฟท์แวร์คุณภาพสูงของค่ายดัง
                                        ล็อบบี้ที่มีดีลเลอร์สดมืออาชีพ
                                        และอัตราจ่ายที่เปิดโอกาสให้ผู้เล่นได้ลุ้นทำกำไรจากเกมสนุก
                                    </p>
                                    <p>Fun88 อีสปอร์ต โฉมใหม่หลากหลายกว่า</p>
                                    <p>
                                        ตอบรับการเติบโตอย่างต่อเนื่องของวงการอีสปอร์ต
                                        Fun88
                                        เปิดให้สมาชิกได้เดิมพันกับแมตช์การแข่งขันสุดสนุก
                                        ผ่าน 2 แพลตฟอร์มใหม่ล่าสุด! TF อีสปอร์ต
                                        และ Fun88 อีสปอร์ต พร้อมแล้ว?
                                        ไปดูกันว่าแต่ละห้องมีดียังไง
                                    </p>
                                    <p>
                                        TF อีสปอร์ต - จุใจกับ 30 เกมดัง
                                        ราคาดีที่สุด
                                        <br />
                                        แพลตฟอร์มพนันอีสปอร์ตใหม่ล่าสุดบนเว็บรับเดิมพัน
                                        Fun88 คือห้อง TF อีสปอร์ต
                                        เปิดให้สมาชิกเลือกพนันการแข่งขันเกมยอดนิยมจากทั่วโลกสูงถึง
                                        30 เกม ไม่ว่าจะเป็น CSGO, DOTA2, LoL,
                                        NBA 2K, Call of Duty, Overwatch, Star
                                        Craft 2, FIFA และอื่นๆ อีกมากมาย
                                        ครอบคลุมทุกลีกและทัวร์นาเมนต์ที่น่าติดตาม
                                        โดยที่ห้องนี้จะเน้นนำเสนอราคาต่อรองแบบบิลชุด
                                        (เช็คได้ที่แถบเมนูด้านบน “คอมโบ”)
                                        และมีอัตราต่อรองล่วงหน้าเปิดให้พนันกันวันละมากกว่า
                                        250+ อัตรา ประเภทการแสดงผลอัตราต่อรอง
                                        มีให้เลือก 3 แบบ ได้แก่ ยูโร,
                                        อินโดนีเซีย และ ดิสก์ฮ่องกง
                                        (เลือกได้ตามความถนัดของคุณ)
                                        นอกจากนี้ยังมีการรายงานผลการแข่งขันสด
                                        พร้อมสตรีมมิ่งให้ติดตามตลอดแมตช์
                                    </p>
                                    <p>
                                        Fun88 อีสปอร์ต - ตลาดหลากหลาย
                                        พนันง่ายบนมือถือ
                                    </p>
                                    <p>
                                        ผู้เล่นที่กำลังมองหาแพลตฟอร์มที่สามารถพนันอีสปอร์ตได้ง่ายๆ
                                        บนมือถือ แนะนำให้ร่วมสนุกกับ Fun88
                                        อีสปอร์ต
                                        เนื่องจากที่นี่มีการนำเสนอหน้าจอและการแสดงผลที่เหมาะกับการพนัน
                                        บนบนมือถือ 100%
                                        พร้อมตัวเลือกราคาต่อรองมากกว่า 400+
                                        อัตราต่อวัน
                                        พร้อมด้วยนำเสนอการพนันในหัวข้อทั่วไป
                                        (ทายทีมชนะ)
                                        และหัวข้อที่เป็นทางเลือกสนุกๆ เช่น
                                        ในตลาดของเกม LoL มีตัวเลือก เกม 2 ศพแรก,
                                        เกม 2 ป้อมปืนแรก, เกม 2 ฆ่าได้ 5 ก่อน
                                        เป็นต้น
                                    </p>
                                    <p>Fun88 สล็อตออนไลน์ ยิงปลา ล่ารางวัล</p>
                                    <p>
                                        ในหมวด{" "}
                                        <span>Fun88 เกมคาสิโนออนไลน์นี้</span>
                                        นำขบวนโดยผู้ผลิตซอฟท์แวร์ค่ายดังที่ทางเว็บไซต์เลือกสรรมาให้กับผู้เล่นในไทยโดยเฉพาะ
                                        ทั้งคุณภาพของกราฟิก, ความสร้างสรรค์,
                                        ความสนุก
                                        รวมถึงคุณภาพและความยุติธรรมในการออกรางวัล
                                        ซึ่งในหมวดนี้ถือเป็นหมวดเกมที่มีสีสัน
                                        เล่นง่ายและเข้าถึงผู้เล่นได้ดีที่สุด
                                        ทุกเพศ ทุกวัย
                                    </p>
                                    <p>
                                        ขั้นตอนการเล่นสล็อต/ยิงปลาออนไลน์ Fun88
                                        <br />
                                        ข้อมูลในส่วนนี้นอกจากที่เราจะมีการแนะนำเกมต่างๆ
                                        ประจำหมวดแล้ว
                                        เราอยากนำเสนอวิธีการร่วมสนุก
                                        และค้นหาเกมที่คุณต้องการไปพร้อมๆ กัน
                                        เริ่มกันเลย!
                                    </p>
                                    <div>
                                        <ol>
                                            <li>
                                                1. เลือกเกมที่ใช่
                                                จากค่ายเกมที่คุณชอบ
                                                <br />
                                                คุณมีเครื่องมือตัวกรองทำหน้าที่ช่วยคัดเลือกเกมที่ต้องการ
                                                ผู้เล่นสามารถเลือกได้จากค่ายเกม
                                                ได้แก่ ยิงปลา Jili (ใหม่ล่าสุด),
                                                Slot PG, Joker, Pragmatic Play
                                                (ฮอต), Microgaming, SG,{" "}
                                                <span>Fun88 NetEnt</span>, BSG,
                                                Playtech และ SW
                                                หรือคุณสามารถเลือกจากประเภทเกม
                                                ได้แก่ เกมยิงปลา, โปรโมชั่นเกม,
                                                ท็อปเกม
                                                จากนั้นระบบจะแสดงรายชื่อเกมตามที่คุณเลือกคัดกรอง
                                                โดยคุณสามารถคลิกบนเกมที่ต้องการได้ทันที
                                            </li>
                                            <li>
                                                2. ล็อกอินสมาชิก Fun88
                                                และฝากเงิน
                                                <br />
                                                ล็อกอินสมาชิก Fun88
                                                และทำการฝากเงิน
                                                ผ่านช่องทางธนาคารไทย
                                                (หรือเงินคริปโต, ทรูวอลเล็ท)
                                                ที่คุณสะดวก
                                                ขั้นต่ำในการฝากเงินอยู่ที่ครั้งละ
                                                100 บาท
                                                (ขึ้นอยู่กับช่องทางที่เลือกด้วย)
                                                จากนั้นรอให้ระบบอนุมัติวงเงินฝากประมาณ
                                                2-30 นาที
                                            </li>
                                            <li>
                                                3. เล่นสล็อต/ยิงปลาฟรี
                                                ระหว่างรอเครดิตเข้า
                                                <br />
                                                ระหว่างนี้คุณสามารถเลือกเล่นเกมสล็อตฟรี
                                                หรือยิงปลาออนไลน์ในหมวดเดโม่ได้เลยโดยไม่ต้องเสียเงิน
                                                เนื่องจากเป็นการร่วมสนุกในหมวดทดลองเล่นฟรี
                                                ระหว่างนี้สามารถทดลองฟีเจอร์พิเศษต่างๆ
                                                ของแต่ละเกมได้อีกด้วย
                                            </li>
                                            <li>
                                                4. ตะลุยสปิน/ยิงปลา
                                                ล่าแต้มสะสมเหรียญ
                                                <br />
                                                หลังจากเครดิตเข้าบัญชีเรียบร้อยแล้ว
                                                และคุณก็ได้ทดลองเล่นฟรีไปแล้วเช่นกัน
                                                ก็ถึงเวลาที่จะลุ้นไปกับเกมสนุกๆ
                                                และล่าเงินรางวัลกันแล้ว!
                                                คลิกเลือกเกมที่ต้องการอีกครั้ง
                                                แล้วไปสนุกลุ้นรางวัลได้ทันที
                                            </li>
                                        </ol>
                                        <div>
                                            Fun88 เกม 3 มิติ
                                            ไฮโลไทยพื้นบ้านบนมือถือ
                                            <br />
                                            เกมพนันแบบไทยๆ จะไม่สูญสิ้น!
                                            เพราะวันนี้คุณสามารถเดิมพันและเล่นไฮโลไทยพื้นบ้านกับ
                                            Fun88 เกม 3 มิติ ได้แล้ว!
                                            เกมสุดสนุกจากค่ายผู้พัฒนา KingMaker,
                                            KingJoker และ Joker
                                            หยิบเอาเกมพื้นบ้านแบบไทย ทั้งไฮโล,
                                            น้ำเต้าปูปลา, โยนเหรียญ และอื่นๆ
                                            อีกมากมาย
                                            มาพัฒนาให้อยู่ในรูปแบบออนไลน์
                                            <br />
                                            และรองรับการเล่นบนสมาร์ทโฟนอย่างสมบูรณ์แบบตัวเกมแสดงภาพแอนิเมชันน่ารักสดใส
                                            เล่นง่าย ด้วยโปรแกรมและปุ่มภาษาไทย
                                            100% กติกาดั้งเดิม
                                            มาพร้อมอัตราจ่ายน่าลุ้น
                                            เป็นหมวดเกมที่เล่นง่าย และเล่นเพลิน
                                            ที่สำคัญที่สุด คือ เกม 3 มิติ
                                            เงินจริงกำลังมาแรงมากในขณะนี้!
                                            เราขอแนะนำให้คุณลอง
                                            <br />
                                            Fun88 หวยไทยรัฐบาล จ่ายสูง
                                            พร้อมหวยสปีด
                                            <br />
                                            แทงหวยไทยออนไลน์กับ{" "}
                                            <span>Fun88 หวยออนไลน์</span>
                                            ได้ทุกงวด
                                            ที่นี่เปิดให้สมาชิกได้เดิมพันหวยไทยรัฐบาลผ่านช่องทางออนไลน์
                                            จ่ายสูงสุดบาทละ 701 บาทเลยทีเดียว
                                            พนันได้ทั้งเลขท้าย 2 ตัว เลขท้าย 3
                                            ตัว หรือจะสนุกกับหวยสปีด
                                            ลอตเตอรี่แนวใหม่ที่คุณไม่ต้องรอเดือนละ
                                            2 งวดอีกต่อไป ออกรางวัลรายนาทีทันใจ
                                            ลุ้นกำไรได้ง่ายกว่าเดิมนอกจากนี้ที่
                                            Fun88 หวย ก็ยังมีเกมอื่นๆ
                                            ให้เลือกเล่น ไม่ว่าจะเป็น
                                            คีโนออนไลน์, หวยเวียดนาม, หวยจีน,
                                            เกมรถแข่ง PK10
                                            ที่ผู้เล่นต้องทายว่ารถหมายเลขใดจะเข้าเส้นชัยก่อน
                                            รวมทั้งเปิดให้เล่นหวยหุ้นตลอด 24
                                            ชั่วโมง
                                            <br />
                                            สมัคร Fun88
                                            ไปบันเทิงและลุ้นรางวัลเลยตอนนี้
                                            <br />
                                            คาสิโนออนไลน์ที่เต็มไปด้วยความครบครัน
                                            ทั้งพอร์ทัลพนันกีฬา เกมคาสิโนสด
                                            ยิงปลา สล็อต เกม 3 มิติ และหวยไทย
                                            ด้วยการพัฒนาอย่างต่อเนื่อง
                                            ภายใต้การให้บริการบนแพลตฟอร์มคุณภาพและมาตรฐานสูงที่สุดในไทยและเอเชีย
                                            Fun88
                                            ยินดีต้อนรับคุณเข้าเป็นส่วนหนึ่งของความสนุกและความบันเทิง
                                            เงินรางวัลและประสบการณ์ที่ดีที่สุดรอคุณอยู่ที่นี่
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`toggle-btn-wrap${
                                    this.state.readMore ? " active" : ""
                                }`}
                            >
                                <Button
                                    size="large"
                                    shape={
                                        this.state.readMore ? "circle" : "round"
                                    }
                                    onClick={() => {
                                        this.setState((prevState) => ({
                                            readMore: !prevState.readMore,
                                        }));
                                    }}
                                >
                                    {this.state.readMore ? (
                                        <Icon
                                            type="up"
                                            style={{
                                                color: "#ffffff",
                                            }}
                                        />
                                    ) : (
                                        "อ่านเพิ่มเติม"
                                    )}
                                </Button>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    supportFormats: state.commonData.imageSupportFormats,
    footerData: state.commonData.cmsFooterImages,
});

const mapDispatchToProps = {
    getCMSFooter: () => getCMSFooter(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
