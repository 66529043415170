/*
 * @Author: Connie
 * @Date: 2023-08-18 13:58:11
 * @LastEditors: Connie
 * @LastEditTime: 2023-08-31 14:30:37
 * @Description: Under Maintenance页面
 * @FilePath: /F1-M2-WEB-Code/components/Maintain/index.js
 */
import React, { useState, useEffect } from "react";
import Router from "next/router";
import Layout from "@/Layout/other";
import { Cookie } from "$ACTIONS/helper";
import { PopUpLiveChat, formatSecond } from "$ACTIONS/util";

export default function CustomMaintain() {
    const [currentTimeCount, setCurrentTimeCount] = useState(0);
    useEffect(() => {
        let timeTimer = null;
        if (Cookie.Get("maintainTime") !== "") {
            const maintainTime = Cookie.Get("maintainTime");
            let lastSeconds = parseInt(
                (new Date(maintainTime).getTime() - new Date().getTime()) / 1000
            );
            timeTimer = setInterval(() => {
                if (lastSeconds <= 0) {
                    Cookie.Delete("maintainTime");
                    clearInterval(timeTimer);
                    global.HttpStatus = 1;
                    Router.push("/");
                }
                setCurrentTimeCount(lastSeconds--);
            }, 1000);
        }
        return () => clearInterval(timeTimer);
    }, []);
    const hour = formatSecond(currentTimeCount)[0];
    const min = formatSecond(currentTimeCount)[1];
    const sec = formatSecond(currentTimeCount)[2];
    return (
        <Layout>
            <div className="error-wrapper">
                <div className="error-img">
                    <img
                        src="/th/img/maintain/maintainImg.jpg"
                        alt="maintainImg"
                    />
                </div>
                <div className="error-right">
                    <div className="error-item">
                        <p
                            className="title"
                            style={{ marginBottom: "10px" }}
                        >
                            เรียนสมาชิก
                        </p>
                        {/* 尊敬的会员 */}
                        <span
                            className="error-mes"
                            style={{ textAlign: "center" }}
                        >
                            เรากำลังอัปเกรดและปรับปรุงระบบ โปรดลองเข้าใช้งาน
                            ในภายหลัง สามารถติดต่อเจ้าหน้าที่ฝ่ายบริการ
                            ผ่านช่องทางต่อไปนี้
                            {/* 我们正在对系统进行升级和完善。 请稍后尝试使用，您可以联系服务人员。 通过以下渠道 */}
                        </span>
                        <span className="maintain-title">คาดว่าจะแล้วเสร็จภายใน:</span>
                        {/* 预计维护： */}
                        <div className="maintain-wrapper">
                            <div>
                                <div className="maintain-item">{hour}</div>
                                <div className="maintain-text">ชั่วโมง</div>
                            </div>
                            <div>
                                <div className="maintain-item">{min}</div>
                                <div className="maintain-text">นาที</div>
                            </div>
                            <div>
                                <div className="maintain-item">{sec}</div>
                                <div className="maintain-text">วินาที</div>
                            </div>
                        </div>
                        <button
                            className="error-btn"
                            onClick={() => PopUpLiveChat()}
                        >
                            <img
                                src="/th/img/error404/service.png"
                                alt="customerServiceIcon"
                            />
                            <span>ฝ่ายบริการลูกค้า</span>
                            {/* 在线客服 */}
                        </button>
                        <div className="error-information">
                            <div className="error-info-text">
                                <span>อีเมล :</span>
                                {/* 电子邮箱: */}
                                <a href="#">cs.thai@fun88.com</a>
                            </div>
                            <div className="error-info-text">
                                <span>โทรศัพท์ :</span>
                                {/* 热线电话: */}
                                <a href="#">+66 600 035 187</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
