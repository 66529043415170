/*
 * @Author: Alan
 * @Date: 2023-05-05 00:41:19
 * @LastEditors: Connie
 * @LastEditTime: 2023-08-22 14:17:53
 * @Description: 用于访问限制 等账户限制页面的框架
 * @FilePath: /F1-M2-WEB-Code/components/Layout/other.js
 */
import React from 'react';
import Head from 'next/head';
import { useReducer, useState, useEffect } from 'react';
import Router from 'next/router';
import { PopUpLiveChat } from "$ACTIONS/util";
export default ({ children, title = '', description = '', Keywords = '', setDevStatus = null, setLoginStatus, from = '' }) => {
	const [ contextValue, dispatch ] = useReducer((state, newState) => ({ ...state, ...newState }), {
		downloadLinks: ''
	});

	return (
		<React.Fragment>
			<div id="maintain" className="common-distance-wrap">
				<div className="maintain-header-wrap" style={{ display: 'flex' }}>
					<div className="common-distance" style={{ display: 'flex', alignItems: 'center' }}>
						<div
							className="logo-wrap inline-block"
							onClick={() => {
								Router.push('/');
							}}
						>
							<img src="/th/img/home/logo@2x.png" alt="FUN88" style={{ width: '128px', height: '28px' }} />
						</div>
						<div className="sponsor-wrap inline-block">
							<div className="tlc-title-partner inline-block">
								<img
									src="/th/img/logo-Sevilla1.png"
									alt="FUN88"
									style={{
										width: '40px',
										marginTop: '-19px',
										marginRight: '10px'
									}}
									width="40px"
								/>
								<div className="tlc-partner-section inline-block" style={{ paddingRight: '20px' }}>
									หุ้นส่วนทางการในเอเชีย <br />
									นิวคาสเซิล
								</div>
							</div>
							{from === "safehouse" && (
                                <div className="tlc-title-partner inline-block">
                                    <div
                                        className="tlc-sprite live-service"
                                        onClick={() => {
											window.open('/th/CustomerService/')
                                        }}
                                    />
                                </div>
                            )}
						</div>
					</div>
				</div>
				<div className="common-distance">{children} </div>
			</div>
		</React.Fragment>
	);
};
