import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router from "next/router";
import { Modal } from "antd";
import ImageWithFallback from "@/ImageWithFallback/imgLocal";
import { setSmarticoErrorModalAction } from "$STORE/thunk/gameThunk";

function SmarticoMaintenance() {
    const dispatch = useDispatch();
    const smarticoMaintenanceModal = useSelector(
        (state) => state.game.smarticoMaintenanceModal
    );

    useEffect(() => {
        if (smarticoMaintenanceModal.visible) {
            global.Pushgtagpiwikurl &&
                global.Pushgtagpiwikurl(
                    "rewards_centre_maintenance_popup",
                    "Rewards Centre Maintenance Popup"
                );
        }
    }, [smarticoMaintenanceModal.visible]);

    const handleOkClick = () => {
        dispatch(setSmarticoErrorModalAction(false));

        if (smarticoMaintenanceModal.backHomePage) {
            Router.push("/");
        }
    };

    return (
        <Modal
            title=""
            visible={smarticoMaintenanceModal.visible}
            className="smartico-maintenance-modal"
            closable={false}
            maskClosable={false}
            width={400}
            centered
            okButtonProps={{
                style: { marginLeft: "0" },
                size: "large",
                block: true,
            }}
            cancelButtonProps={{ style: { display: "none" } }}
            okText="ตกลง" //確定
            onOk={() => {
                handleOkClick();
            }}
        >
            <div className="content">
                <ImageWithFallback
                    src={"/th/img/icons/icon-warn_yellow.png"}
                    width={60}
                    height={60}
                    alt="Warning"
                    fallbackSrc="/th/img/logo.svg"
                />
                <div style={{ fontSize: 14, color: "#222", marginTop: 16 }}>
                    บริการนี้ใช้งานไม่ได้ชั่วคราว กรุณาลองอีกครั้งในภายหลัง
                    {/* 系统维护中，请稍后尝试哦 */}
                </div>
            </div>
        </Modal>
    );
}

export default SmarticoMaintenance;
