/*
 * @Author: Alan
 * @Date: 2023-05-09 18:01:54
 * @LastEditors: Alan
 * @LastEditTime: 2023-07-12 14:55:54
 * @Description: 游戏资料相关请求
 * @FilePath: /F1-M2-WEB-Code/actions/gameRequest.js
 */
import { ApiPort } from "$ACTIONS/TLCAPI";
import { get } from "$ACTIONS/TlcRequest";
/**
 * @description:取得游戏分类导航相关資料 （加入本地存储 优化加载速度体验）
 * @return {*}
 */
export async function GetCategoryList() {
    let mergedArray = JSON.parse(localStorage.getItem("GameSequenceData"));

    try {
        const res = await get(ApiPort.GameCategory);
        mergedArray = res.result;
        localStorage.setItem("GameSequenceData", JSON.stringify(mergedArray));
    } catch (error) {
        console.error(error);
    }
    return mergedArray;
}

/**
 * @description: 获取Flash Provider 数据主要是用于平台列表
 * @param {*} categoryType 当前游戏的分类 类型
 * @param {*} gameCatId 当前游戏分类的 ID
 * @return {*}
 */
export async function GetFlashProviderList(
    categoryType,
    isShowFishingGames = false
) {
    let providerList = JSON.parse(
        localStorage.getItem(`Flash_${categoryType}_Provider`) || "[]"
    );
    try {
        const res = await get(
            ApiPort.ProvidersDetails +
                `?gameType=${categoryType}&isShowFishingGames=${isShowFishingGames}`
        );

        if (res && res.isSuccess) {
            providerList = res.result;
            localStorage.setItem(
                `Flash_${categoryType}_Provider`,
                JSON.stringify(providerList)
            );
        }
    } catch (e) {
        console.error("Error fetching provider list from API", e);
    }

    return providerList || [];
}

export async function GetSmarticoToggle() {
    try {
        return await get(ApiPort.GETSmarticoToggle);
    } catch (error) {
        console.error(error);
        return null;
    }
}
