import React from "react";
import { Provider } from "react-redux";
import { Modal, Tabs, Icon } from "antd";
import Login from "./Login";
import Register from "./Register";
import ForgotPwd from "@/ForgotPwd/";
import { store } from "$STORE/store";
import { connect } from "react-redux";
import { gameActions } from "$STORE/gameSlice";
const { TabPane } = Tabs;
class UserSign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: "1",
            visible: false,
        };

        this.Showmodal = React.createRef();
    }

    componentDidMount() {
        global.goUserSign = (key) => this.showmodal(key); // 全局化登陆注册弹窗事件
        this.props.onUserSign(this.showmodal); // 传出登陆注册弹窗事件
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.visible !== this.state.visible && this.state.visible) {
            if (this.state.activeKey === "1") {
                global.Pushgtagpiwikurl &&
                    global.Pushgtagpiwikurl("login", "Login");
            } else if (this.state.activeKey === "2") {
                global.Pushgtagpiwikurl &&
                    global.Pushgtagpiwikurl("register", "Register");
            }
        }
    }
    showmodal = (key) => {
        this.setState({
            visible: !!key,
            activeKey: key,
        });
    };

    handleEvent = (callback) => {
        this.setState(
            {
                visible: false,
            },
            () => {
                typeof callback === "function" && callback();
            }
        );
    };

    RefLogin = (ref) => {
        this.Login = ref;
    };

    render() {
        return (
            <>
                <Modal
                    title="ยินดีต้อนรับ" //欢迎您
                    className="modal-pubilc login-and-registration"
                    visible={this.state.visible}
                    // onOk={this.handleEvent}
                    onCancel={() => {
                        this.handleEvent(() => {
                            this.props.cacheGame &&
                                this.props.setCacheGame(null);
                        });
                    }}
                    zIndex={2000}
                    width={400}
                    footer={null}
                    destroyOnClose={true}
                    style={{ top: 180 }}
                >
                    <Provider store={store}>
                        <Tabs
                            tabPosition="top"
                            className="tabs-modal-pubilc"
                            size="small"
                            activeKey={this.state.activeKey}
                            onChange={(activeKey) => {
                                this.setState({ activeKey });
                                activeKey === "1"
                                    ? Pushgtagdata(
                                          "Register",
                                          "Switch to Login",
                                          "Register_C_Login"
                                      )
                                    : Pushgtagdata(
                                          "Login",
                                          "Switch to Register",
                                          "Login_C_Register"
                                      );
                                global.Pushgtagpiwikurl &&
                                    global.Pushgtagpiwikurl(
                                        activeKey === "1"
                                            ? "login"
                                            : "register",
                                        activeKey === "1" ? "Login" : "Register"
                                    );
                            }}
                        >
                            <TabPane tab="เข้าสู่ระบบ" key={"1"}>
                                {/* 登入 */}
                                <Login
                                    RefLogin={(ref) => this.RefLogin(ref)}
                                    AlreadLogin={() => this.props.AlreadLogin()}
                                    handleEvent={() => this.handleEvent()}
                                    openForgotPwd={() => {
                                        this.handleEvent();
                                        this.Showmodal.current.showModal("1");
                                    }}
                                />
                            </TabPane>
                            <TabPane tab="ลงทะเบียน" key={"2"}>
                                {/* 注册 */}
                                <Register
                                    login={(UserName, UserPwd) => {
                                        this.Login.Registerdata(
                                            UserName,
                                            UserPwd
                                        );
                                    }}
                                    showmodal={(e) => this.showmodal(e)}
                                    closeModal={this.handleEvent}
                                />
                            </TabPane>
                        </Tabs>
                    </Provider>
                </Modal>
                <ForgotPwd ref={this.Showmodal} />
            </>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        cacheGame: state.game.cacheGame,
    };
};

const mapDispatchToProps = {
    setCacheGame: (data) => gameActions.setCacheGame(data),
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSign);
