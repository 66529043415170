import { useState } from "react";
import { useRouter } from "next/router";
import { Button, Modal, Checkbox } from "antd";
import { ApiPort } from "$ACTIONS/TLCAPI";
import { post } from "$ACTIONS/TlcRequest";

function NewMemPromoModal(props) {
    const [isChecked, setIsChecked] = useState(true);
    const { visible, onClose } = props;
    const router = useRouter();
    const setWelcomeCall = () => {
        post(ApiPort.POSTWelcomeCall + "&isWelcomeCall=" + isChecked)
            .then((res) => {
                console.log("POSTWelcomeCall success:", res);
            })
            .catch((error) => {
                console.log("POSTWelcomeCall error:", error);
            });
    };
    return (
        <Modal
            className="newMember__promotion__modal"
            centered={true}
            visible={visible}
            onCancel={onClose}
            closable={false}
            width={500}
            footer={null}
        >
            <img
                className="headerImg"
                src="/th/img/home/TH_NewMemberPromotionDesktop-Banner.jpg"
            />
            <main>
                <ul className="promotionList">
                    <li>
                        ฝากเงินครั้งแรกสุดคุ้ม คอมโบแพ็กเกจ
                        <br />
                        รับโบนัสเงินฟรีและของพรีเมี่ยม
                    </li>
                    <li>
                        โบนัสแรกเข้า 120% สำหรับสล็อต
                        <br />
                        รับเพิ่มยิ่งขึ้นเมื่อคุณเข้าร่วมสนุกกับ FUN88!
                    </li>
                    <li>ยิ่งแนะนำเพื่อนมาก ยิ่งได้รางวัลพิเศษ</li>
                </ul>
                <Checkbox
                    className="checkbox_allow_cs"
                    checked={isChecked}
                    onChange={() => {
                        setIsChecked((prevState) => !prevState);
                    }}
                >
                    ฉันต้องการและยินดีรับการติดต่อจากเจ้าหน้าที่
                </Checkbox>
                {isChecked && (
                    <p>
                        เจ้าหน้าที่ของเราจะติดต่อคุณโดยเร็วที่สุด ตามเบอร์ โทรศัพท์ที่
                        <br />
                        ลงทะเบียนไว้ ขอบคุณค่ะ
                    </p>
                )}
                <Button
                    type="primary"
                    size="large"
                    block
                    onClick={() => {
                        onClose();
                        setWelcomeCall();
                        global.showDialog({
                            key: 'wallet:{"type": "deposit"}',
                        });
                    }}
                >
                    ฝากเงินตอนนี้
                </Button>
                <div
                    className="later"
                    onClick={() => {
                        onClose();
                        setWelcomeCall();
                        router.push("/");
                    }}
                >
                    ฝากภายหลัง
                </div>
            </main>
        </Modal>
    );
}

export default NewMemPromoModal;
