import { userCenterActions } from "$STORE/userCenterSlice";
import { getMemberInfo } from "$DATA/userinfo";
import { checkIsRemoveShaba } from "$ACTIONS/util";
import { setGameHideAction } from "$STORE/thunk/gameThunk";

export function getMemberInfoAction(callback, refresh = true) {
    return async function getMemberInfoThunk(dispatch, getState) {
        try {
            const memberInfo = await getMemberInfo(refresh);
            if (!memberInfo) {
                dispatch(userCenterActions.setMemberInfo({}));
            } else {
                dispatch(userCenterActions.setMemberInfo(memberInfo));
                /*
                    //根據註冊日期判斷，是否隱藏沙巴
                    if (checkIsRemoveShaba(memberInfo.registerDate)) {
                        dispatch(setGameHideAction("OWS"));
                    }
                */
            }

            if (typeof callback === "function") {
                callback(memberInfo ?? {});
            }
        } catch (e) {
            console.error("thunk getMemberInfo error !", e.message);
        }
    };
}
