import React from "react";

class Loading extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { visible, loadingText } = this.props;
        return visible ? (
            <div className="wallet-mask">
                <div className="wallet-loading">
                    <img
                        src="/th/img/icons/loading.gif"
                        style={{ width: "24px", height: "24px" }}
                        alt="loading"
                    />
                    <p
                        style={{
                            marginTop: 10,
                            marginBottom: 0,
                            padding: 0,
                        }}
                    >
                        {loadingText}
                    </p>
                </div>
            </div>
        ) : null;
    }
}
export default Loading;
