import { message } from "antd";

const defaultTop = 200; // 默认的 top 位置
const defaultDuration = 3; // 默认的显示时间 (秒)

const configureMessage = (top = defaultTop) => {
    message.config({
        top,
    });
};

const showMessage = (
    type,
    content,
    top = defaultTop,
    duration = defaultDuration
) => {
    // 临时配置 message 位置
    configureMessage(top);

    switch (type) {
        case "success":
            message.success(content, duration);
            break;
        case "error":
            message.error(content, duration);
            break;
        case "info":
            message.info(content, duration);
            break;
        case "warning":
            message.warning(content, duration);
            break;
        case "loading":
            message.loading(content, duration);
            break;
        default:
            message.info(content, duration);
    }

    // 恢复默认 top 位置
    configureMessage();
};

export default showMessage;
