import React, { useEffect, useRef, useState } from "react";
import Router from "next/router";
import { get, post } from "$ACTIONS/TlcRequest";
import { ApiPort, APISETS, APISET } from "$ACTIONS/TLCAPI";
import Flexbox from "@/Flexbox/";
import Image from "next/image";
import { lazyLoadImg } from "$ACTIONS/util";
import { Carousel, Menu, Icon, Button, Col, message, Alert } from "antd";
import QRCode from "qrcode-react";
import {
    menuBarData,
    menuRightBarData,
    hoverSecondary,
    hoverSecondaryDetail,
    menuListInt,
} from "$DATA/header.static";
import { mapGameProduct } from "$DATA/gameUtils";
import { connect } from "react-redux";
import { promotionActions } from "$STORE/promotionSlice";
import { getGameCategoryListAction, getSmarticoToggleAction } from "$STORE/thunk/gameThunk";
import classNames from "classnames";
import ImageWithFallback from "@/ImageWithFallback/imgLocal";
import HostConfig from "$ACTIONS/Host.config";
import { DEFAULT_AFFILIATE_URL } from "$STORE/mainSiteConfigSlice";

let currentPage = {
    LiveCasino: { index: 0, className: "hide-prev" },
    Slot: { index: 0, className: "hide-prev" },
    More: { index: 0, className: "hide-prev" },
};

function HoverContent(props, hoverData, detailData, secondaryMenuBar) {
    const GameSlider = useRef();

    useEffect(() => {
        if (currentPage[detailData.type]) {
            GameSlider.current.goTo(currentPage[detailData.type].index);
        }
    }, []);

    function onChange(from, to) {
        // console.log("detailData.type",detailData.type);
        let menuLength;
        if (currentPage[detailData.type]) {
            currentPage[detailData.type].index = to;
            menuLength = secondaryMenuBar.length - 1;
            if (currentPage[detailData.type].index === 0) {
                currentPage[detailData.type].className = "hide-prev";
            } else if (currentPage[detailData.type].index === menuLength) {
                currentPage[detailData.type].className = "hide-next";
            } else {
                currentPage[detailData.type].className = "";
            }
        }
    }

    //console.log("secondaryMenuBar", secondaryMenuBar);
    return (
        <div className="Content-List">
            {detailData.type != "More" ? (
                <div className="menu-sign-bar">
                    <h2>
                        {props.itemdata && props.itemdata.name
                            ? props.itemdata.name
                            : "ไม่มีข้อมูล"}
                        {/* 暂无数据 */}
                    </h2>
                    <p className="sign-color">
                        {detailData.english || "ไม่มีข้อมูล"}
                        {/* 暂无数据 */}
                    </p>
                    <div className="sign-brief">
                        <p>
                            {detailData.promotion || "ไม่มีข้อมูล"}
                            {/* 暂无数据 */}
                        </p>
                        <p className="sign-color">
                            {detailData.promotionVal || "ไม่มีข้อมูล"}
                            {/* 暂无数据 */}
                            <span>{detailData.promotionValSuffix || "ไม่มีข้อมูล"}</span>
                        </p>
                    </div>
                </div>
            ) : (
                <div className="menu-sign-bar ">
                    <h2>{detailData.name}</h2>
                    <p className="sign-color">{detailData.english}</p>
                </div>
            )}
            <div
                className={`menu-bar${
                    currentPage[detailData.type]
                        ? " " + currentPage[detailData.type].className
                        : ""
                }`}
            >
                {/* 游戏左右滑动列表 */}
                <Carousel
                    ref={GameSlider}
                    arrows={true}
                    beforeChange={onChange}
                >
                    {secondaryMenuBar.map((value, index) => {
                        return (
                            <div key={index} className="menu-bar-pages">
                                {value.map((innerItem, innerIndex) => {
                                    return (
                                        <div
                                            id={
                                                innerItem.category +
                                                "_" +
                                                innerItem.code
                                            }
                                            className={`menu-bar-children`}
                                            key={innerIndex}
                                            onClick={() => {
                                                if (
                                                    innerItem.type !== "static"
                                                ) {
                                                    // 直接開啟遊戲類型
                                                    if (
                                                        innerItem.type ===
                                                        "Game"
                                                    ) {
                                                        if (
                                                            innerItem?.code?.toLowerCase() ===
                                                            "aviator"
                                                        ) {
                                                            Pushgtagdata(
                                                                "Home",
                                                                `Launch HotGame Aviator`,
                                                                `Home_InstantGames_C_HotGame`,
                                                                false,
                                                                [
                                                                    {
                                                                        customVariableKey:
                                                                            "Home_InstantGames_C_HotGame_GameName",
                                                                        customVariableValue: "Aviator",
                                                                    },
                                                                ]
                                                            );
                                                        } else {
                                                            Pushgtagdata(
                                                                "Home",
                                                                `Launch ${innerItem.code}`,
                                                                `Home_${innerItem.category}_C_${innerItem.code}`
                                                            );
                                                        }
                                                        // 跳轉 list 頁類型
                                                    } else if (
                                                        innerItem.type ===
                                                        "Category"
                                                    ) {
                                                        Pushgtagdata(
                                                            "Home",
                                                            `Go to ${innerItem.code} Lobby`,
                                                            `Home_${innerItem.category}_C_${innerItem.code}`
                                                        );
                                                    }
                                                }
                                            }}
                                        >
                                            {/* 静态的菜单，More tab */}
                                            {innerItem.type === "static" && (
                                                <div
                                                    className="list-content"
                                                    onClick={() => {
                                                        if (innerItem?.provider == '/faq/abount-Fun88') {
                                                            window.open('/th/CustomerService')
                                                        } else {
                                                            props.goPages(
                                                                innerItem.provider
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <ImageWithFallback
                                                        src={
                                                            innerItem.providerImageUrl ||
                                                            "/th/img/logo.svg"
                                                        }
                                                        width={100}
                                                        height={100}
                                                        alt={innerItem.provider}
                                                        fallbackSrc="/th/img/logo.svg"
                                                    />
                                                    <p>
                                                        {innerItem.providerTitle ||
                                                            innerItem.title}
                                                    </p>
                                                </div>
                                            )}
                                            {innerItem.type !== "static" && (
                                                <OpenGame
                                                    itemsData={{
                                                        Type: "HeaderMenu",
                                                        gameName:
                                                            innerItem.name,
                                                        provider:
                                                            innerItem.code,
                                                        gameId: innerItem.providerId,
                                                        imageUrl:
                                                            innerItem.image ||
                                                            "/th/img/logo.svg",
                                                        gameCatCode:
                                                            innerItem.category,
                                                        OpenGamesListPage:
                                                            innerItem.type ===
                                                            "Category", //Game、Category
                                                        isNew: innerItem.isNew,
                                                        isHot: innerItem.isHot,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </Carousel>
            </div>
            {detailData.type === "More" ? (
                <div className="menu-section app-down">
                    <h3>ดาวน์โหลดแอป</h3>
                    {/* 扫码下载APP */}
                    <div>
                        <QRCode
                            //logo={'/th/img/download/qrlogo.jpg'}
                            size={80}
                            key={props.downloadLinks}
                            value={props.downloadLinks}
                            // logoWidth={30}
                            // logoHeight={30}
                        />
                    </div>
                    {/* <p className="app-down-address">{global.downloadLinks}</p> */}
                    <span
                        className="ant-btn ant-btn-primary ant-btn-sm app-down-address"
                        onClick={() => {
                            Pushgtagdata(
                                "Home_TopNav",
                                "Download App",
                                "Home_TopNav_C_DownloadApp"
                            );
                            Router.push("/download", "/ดาวน์โหลด-fun88");
                        }}
                    >
                        ดาวน์โหลด
                        {/* 立即下载 */}
                    </span>
                </div>
            ) : (
                <div className="menu-section">
                    <p>{props.itemdata && props.itemdata.description}</p>
                    <Button
                        type="link"
                        ghost
                        onClick={() => {
                            Pushgtagdata(
                                "Home",
                                `View ${detailData.type} Listing`,
                                `Home_C_${detailData.type}`
                            );
                            Router.push(detailData.gameAddress);
                        }}
                    >
                        ดูเพิ่มเติม
                        {/* 了解更多 */}
                        <Icon type="right" />
                        <Icon type="right" />
                    </Button>
                </div>
            )}
        </div>
    );
}

/* 二级菜单Dom结构 url是跳转路径、图片层级路径 */
function SecondaryDom(props) {
    let secondaryMenuBar = [],
        pageSize = -1;
    //console.log("hoverSecondary", hoverSecondary);

    if (props.index < 7) {
        hoverSecondary[props.index] = props.ProviderData;
    } else {
        hoverSecondary[props.index] = hoverSecondary[props.index];
    }

    let hoverData = hoverSecondary[props.index];
    if (props.index === 0) {
        hoverData = hoverData.filter(
            (data) => data.code !== "SB2" && data.code !== "YBS"
        );
    }
    const detailData = hoverSecondaryDetail[props.index];
    if (Array.isArray(hoverData) && hoverData.length && hoverData.length) {
        hoverData.forEach((item, index) => {
            if (index % 5 === 0) {
                pageSize++;
            }
            if (!secondaryMenuBar[pageSize]) {
                secondaryMenuBar[pageSize] = [];
            }

            secondaryMenuBar[pageSize].push(item);
        });

        //console.log("二级菜单", hoverData);
        return (
            <div
                className={`menu-placeholder-bar ${detailData.type}`}
                key={props.index + "Menu"}
            >
                <div className="menu-placeholder-inner-bar">
                    {/* Hover游戏菜单 两侧的文字说明和介绍 */}
                    {/* {secondaryMenuBar.map((Item, Index) => {
						return HoverContent(props, hoverData, detailData, [
							secondaryMenuBar[Index]
						]);
					})} */}
                    {HoverContent(
                        props,
                        hoverData,
                        detailData,
                        secondaryMenuBar
                    )}
                </div>
            </div>
        );
    } else {
        return null;
    }
}

import { getUrlWithAffCode } from "$ACTIONS/helper";
import OpenGame from "@/Games/openGame";
class HeaderMenuBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            route: "",
            Navigation: menuListInt,
            asPath: "/",
            a: 1,
            downloadLinks: "",
            ProviderData: [],
        };
        this.data = [];

        this.goPages = this.goPages.bind(this); // 导航菜单界面跳转
    }
    async componentDidMount() {
        this.setState({ route: Router.router.pathname });

        //this.GetGameID();
        //获取导航菜单数据和导航菜单游戏分类数据
        this.props.getGameCategoriesList();
        this.props.getSmarticoToggle();

        const url = await getUrlWithAffCode();
        global.downloadLinks = url;
        this.setState({ downloadLinks: url });

        // Header 二级菜单图片延迟加载
        const lazyLoadSecondImg = () => {
            lazyLoadImg("t_menu_wrapper");
        };
        document.getElementById("t_menu_box_1").onmouseenter =
            lazyLoadSecondImg;
        document.getElementById("t_menu_box_2").onmouseenter =
            lazyLoadSecondImg;
    }
    componentWillUnmount() {
        document.getElementById("t_menu_box_1").onmouseenter = null;
        document.getElementById("t_menu_box_2").onmouseenter = null;
    }

    /**
     * @description: 取得Reward page網址
     * @param {*}
     * @return {*}
     */
    getRewardLink = () => {
        if (HostConfig.Config.isOnlyLIVE) {
            const domain = window.location.origin.replace("www", "rewards");
            return `${domain}/Rewards/MyVIP.aspx`;
        } else {
            return "https://rewards.fun88.biz/Rewards/MyVIP.aspx";
        }
    };

    goPages(link) {
        switch (link) {
            case "/tournament":
                Pushgtagdata(
                    "Home_TopNav",
                    "Go to Tournament Page",
                    "Home_TopNav_C_TournamentPage"
                );
                break;
            case "/referrer-activity":
                Pushgtagdata(
                    "Home_TopNav",
                    "Go to Refer Friend",
                    "Home_TopNav_C_ReferFriend"
                );
                break;
            case "/diamond-club":
                Pushgtagdata("Home_TopNav", "Go to VIP", "Home_TopNav_C_VIP");
                break;
            case "/faq/abount-Fun88":
                Pushgtagdata(
                    "Home_TopNav",
                    "Go to Help Center",
                    "Home_TopNav_C_HelpCenter"
                );
                break;
            case "/sponsorship":
                Pushgtagdata(
                    "Home_TopNav",
                    "Go to Affiliate Page",
                    "Home_TopNav_C_Affiliate"
                );
                break;
            case "/ดาวน์โหลด-fun88":
                Pushgtagdata(
                    "Home_TopNav",
                    "Download App",
                    "Home_TopNav_C_DownloadCenter"
                );
                break;
            case "/me/self-exclusion":
                Pushgtagdata(
                    "Home_TopNav",
                    "Go to Self Exclusion",
                    "Home_TopNav_C_SelfExclusion"
                );
                break;
            default:
                break;
        }
        if (link === "/referrer-activity") {
            global.goReferFriend();
            // message.info("即将开启，敬请期待！");
            return;
        }
        if (link === "/ดาวน์โหลด-fun88") {
            Router.push("/download", "/ดาวน์โหลด-fun88");
        } else {
            Router.push(link);
        }
    }

    render() {
        const { GameCategory, GameHide, storeAffiliateUrl } = this.props;
        console.log(GameHide);
        return (
            <div id="t_menu_wrapper" className="menu-wrapper">
                <Menu
                    id="t_menu_box_1"
                    className={classNames({
                        ["ant-col-17 Header-Menu Header-Menu-list"]: true,
                        ["Menu_item_Null"]: true,
                    })}
                    mode="horizontal"
                    selectable={false}
                >
                    {/* 首页 */}
                    <Menu.Item className={"Home-Icon"}>
                        <a
                            onClick={() => {
                                Router.push("/");
                            }}
                            aria-label="前往首页"
                        >
                            <i className="tlc-sprite home" />
                        </a>
                    </Menu.Item>
                    {/* 游戏导航菜单 */}
                    {GameCategory.length != 0 &&
                        GameCategory.map((item, index) => {
                            // 新增 category 字段
                            const providersWithCategory =
                                item.subProviders &&
                                item.subProviders.map((provider) => ({
                                    ...provider,
                                    category: item.code,
                                    providerId: 0,
                                }));
                            if (item.expression) {
                                return;
                            }
                            return (
                                <Menu.Item key={item.code}>
                                    <Flexbox
                                        alignItems="center"
                                        onClick={() => {
                                            Pushgtagdata(
                                                "Home_TopNav",
                                                `Go to ${item.code} Lobby`,
                                                `Home_TopNav_C_${item.code}`
                                            );
                                            Router.push({
                                                pathname: `/${
                                                    mapGameProduct.find(
                                                        (v) =>
                                                            v.productCode ===
                                                            item.code
                                                    ).name
                                                }`,
                                            });
                                        }}
                                        className={classNames({
                                            ["ant-menu-item-active-list"]:
                                                this.state.route.indexOf(
                                                    item.code
                                                ) != -1,
                                            ["Menu_item"]: true,
                                        })}
                                    >
                                        {item.name}

                                        {item?.labels === "New" ? (
                                            <div className="SetNew">NEW</div>
                                        ) : item?.labels === "Hot" ? (
                                            <div className="SetHot">HOT</div>
                                        ) : null}
                                        <i className="tlc-sprite" />
                                    </Flexbox>

                                    <SecondaryDom
                                        index={index}
                                        itemdata={item}
                                        // 传入已经增加了 category 字段的 subProviders 数组
                                        ProviderData={providersWithCategory}
                                    />
                                </Menu.Item>
                            );
                        })}
                    {/* 空的占位 防止偏移 */}
                    {GameCategory.length == 0 &&
                        [...Array(7)].map((_, index) => (
                            <Menu.Item key={index} />
                        ))}
                </Menu>
                {/* 右边的菜单 */}
                <Menu
                    id="t_menu_box_2"
                    className="ant-col-7 Header-Menu Header-Menu-list-Right"
                    mode="horizontal"
                    selectable={false}
                >
                    {menuRightBarData.map((item, idx) => {
                        return typeof item.isDown !== "undefined" ? (
                            <Menu.Item key={item.key + idx}>
                                <Flexbox
                                    alignItems="center"
                                    className={classNames({
                                        ["ant-menu-item-active-list"]:
                                            this.state.route.indexOf(
                                                item.key
                                            ) != -1
                                                ? "ant-menu-item-active"
                                                : "",
                                        ["Menu_item"]: true,
                                    })}
                                    onClick={() => {
                                        Pushgtagdata(
                                            "Home_TopNav",
                                            "View More Feature",
                                            "Home_TopNav_C_MoreFeature"
                                        );
                                    }}
                                >
                                    {item.expression}
                                    <i className="tlc-sprite" />
                                </Flexbox>

                                <SecondaryDom
                                    index={item.isDown}
                                    goPages={this.goPages}
                                    downloadLinks={this.state.downloadLinks}
                                    // ProviderData={item.isDown}
                                />
                            </Menu.Item>
                        ) : (
                            <Menu.Item key={item.key + idx}>
                                <Flexbox
                                    alignItems="center"
                                    className={classNames({
                                        ["ant-menu-item-active-list"]:
                                            this.state.route.indexOf(
                                                item.key
                                            ) != -1
                                                ? "ant-menu-item-active"
                                                : "",
                                        ["Menu_item"]: true,
                                    })}
                                    onClick={() => {
                                        if (item.key == "Affiliate") {
                                            Pushgtagdata(
                                                "Home_TopNav",
                                                "Go to Affiliate Page",
                                                "Home_TopNav_C_Affiliate"
                                            );
                                            window.open(
                                                storeAffiliateUrl || DEFAULT_AFFILIATE_URL,
                                                `_blank`
                                            );
                                        } else if (item.key == "Rewards") {
                                            Pushgtagdata(
                                                "Home_TopNav",
                                                "Go to Rewards",
                                                "Home_TopNav_C_Rewards"
                                            );
                                            window.open(
                                                this.getRewardLink(),
                                                `_blank`
                                            );
                                            return;
                                        } else if (item.key === "Promotions") {
                                            Pushgtagdata(
                                                "Home_TopNav",
                                                "Go to Promotion",
                                                "Home_TopNav_C_Promotion"
                                            );
                                            this.props.changeTab("1");
                                            Router.push(
                                                "/promotions",
                                                "/โปรโมชั่น"
                                            );
                                            // 在 Promotions 頁點擊才會觸發
                                            Router.router.pathname ==
                                                "/promotions" &&
                                                this.props.getPromotionList();
                                        } else if (item.key === "DailyDeal") {
                                            Pushgtagdata(
                                                "Home_TopNav",
                                                "Go to Daily Deal",
                                                "Home_TopNav_C_DailyDeal"
                                            );
                                            this.props.changeTab("4");
                                            Router.push(
                                                "/promotions",
                                                "/โปรโมชั่น"
                                            );
                                        }
                                    }}
                                >
                                    {item.expression}
                                </Flexbox>
                            </Menu.Item>
                        );
                    })}
                </Menu>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        GameCategory: state.game.categories,
        GameHideArray: state.game.gamesToFilter,
        storeAffiliateUrl: state.mainSiteConfig.affiliateUrl,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        changeTab: (i) => {
            dispatch(promotionActions.changeTab(i));
        },
        getGameCategoriesList: () => {
            dispatch(getGameCategoryListAction());
        },
        getSmarticoToggle: () => {
            dispatch(getSmarticoToggleAction());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenuBar);
